
import { defineComponent } from 'vue';
import store from '@/store/index';

export default defineComponent({
    name: "UiPasswordInput",
    data(){
        return{
            store,
            modelValue: '',
            inputType: 'password',
            icons: {
                show: '<svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M9.26999 8.85004C10.8771 8.85004 12.18 7.54718 12.18 5.94003C12.18 4.33288 10.8771 3.03003 9.26999 3.03003C7.66284 3.03003 6.35999 4.33288 6.35999 5.94003C6.35999 7.54718 7.66284 8.85004 9.26999 8.85004Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M9.27002 11.03C12.82 11.03 14.95 9.61998 17.27 5.93998C14.95 2.25998 12.82 0.849976 9.27002 0.849976" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M9.27002 11.03C5.72002 11.03 3.59002 9.61998 1.27002 5.93998C3.59002 2.25998 5.72002 0.849976 9.27002 0.849976" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></g></svg>',
                hide: '<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.42 4.89001C11.95 5.42001 12.27 6.14001 12.27 6.95001C12.27 8.56001 10.97 9.86002 9.35999 9.86002C8.55999 9.86002 7.82999 9.53001 7.29999 9.01001" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M6.45001 6.94003C6.45001 5.33003 7.74999 4.03003 9.35999 4.03003" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M11.36 2.02998C10.74 1.90998 10.08 1.84998 9.35999 1.84998" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M5.23999 11.0701C6.41999 11.7401 7.75 12.0301 9.37 12.0301C12.92 12.0301 15.05 10.6201 17.37 6.94006C16.11 4.94006 14.9 3.61006 13.5 2.81006" stroke="#1B1B1B" stroke-miterlimit="10"/><path d="M3.59 9.80997C2.83 9.06997 2.11 8.11998 1.37 6.93998C3.69 3.25998 5.82 1.84998 9.37 1.84998" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M3.54999 12.76L15.18 1.13" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>',
            }
        }  
    },
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        }
    },
    computed:{
        icon() {
            if(this.inputType=='password') {
                return this.icons.show;
            } else {
                return this.icons.hide;
            }
        }
    },
    watch:{
        modelValue() {
            this.$emit('onChange', this.modelValue);
        },
        value() {
            this.modelValue = this.value
        }
    },
    methods:{
        handleToggleInputType() {
            if(this.inputType=='password') {
                this.inputType='text';
            } else {
                this.inputType='password';
            }
        }
    }
})
