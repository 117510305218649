
import { defineComponent } from 'vue';
import { UiButton, UiIconButton } from '@/ui/';

export default defineComponent({
    name: "UiDialog",
    components: { UiButton, UiIconButton },
    props: {
        submitting:{
            type: Boolean,
            default: false
        },
        headline: {
            type: String,
            default: ""
        },
        confirmCaption: {
            type: String,
            default: ""
        },
        hideCancel:{
            type: Boolean,
            default: false
        },
        confirmDisabled:{
            type: Boolean,
            default: false
        },
        hasActions:{
            type: Boolean,
            default: false
        },
        hasClose: {
            type: Boolean,
            default: false
        },
        labels: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            icons: {
                close: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.820007 17.22L16.82 1.21997" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M0.820007 1.21997L16.82 17.22" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>'
            },
            settings: {
                labels: {
                    cancel: this.$t('button.cancel'),
                    ok: this.$t('button.ok')
                }
            }
        }
    },
    mounted() {
        this.settings.labels.cancel = this?.labels?.cancel || this.settings.labels.cancel
        this.settings.labels.ok = this?.labels?.ok || this.settings.labels.ok
        document.addEventListener('keydown', this.onDocumentKeyDown);
        document.body.classList.add('ww-has-dialog')
    },
    unmounted() {
        document.removeEventListener('keydown', this.onDocumentKeyDown);
        document.body.classList.remove('ww-has-dialog')
    },
    methods:{
        onDocumentKeyDown(e : any){
            if (e.key == "Escape" || e.keyCode == 27) {
                this.onCancelClick(e);
            }
        },
        onSubmitClick(e:Event) {
            this.$emit('onOk', e);
        },
        onCancelClick(e:Event) {
            this.$emit('onCancel', e);
        }
    }
})
