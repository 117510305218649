import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ww-dialog",
  class: "ww-dialog"
}
const _hoisted_2 = { class: "ww-dialog-window" }
const _hoisted_3 = { class: "ww-dialog-header" }
const _hoisted_4 = { class: "ww-dialog-content" }
const _hoisted_5 = {
  key: 0,
  class: "ww-dialog-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIconButton = _resolveComponent("UiIconButton")!
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.headline), 1),
        (_ctx.hasClose)
          ? (_openBlock(), _createBlock(_component_UiIconButton, {
              key: 0,
              icon: _ctx.icons.close,
              onOnClick: _ctx.onCancelClick
            }, null, 8, ["icon", "onOnClick"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.hasActions)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (!_ctx.hideCancel)
              ? (_openBlock(), _createBlock(_component_UiButton, {
                  key: 0,
                  text: _ctx.settings.labels.cancel,
                  dense: true,
                  onOnClick: _ctx.onCancelClick
                }, null, 8, ["text", "onOnClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_UiButton, {
              text: _ctx.confirmCaption ? _ctx.confirmCaption : _ctx.settings.labels.ok,
              primary: true,
              raised: true,
              waitForResponse: _ctx.submitting,
              disabled: _ctx.confirmDisabled,
              onOnClick: _ctx.onSubmitClick
            }, null, 8, ["text", "waitForResponse", "disabled", "onOnClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}