import type { App } from "vue";
import type { Options } from './confirm.d';
import createConfirm from "./render";

/**
 * **Options:**
 * @property `type`: 'error' | 'success'`
 * @property `labelConfirm`: label for the confirm button
 * @property `labelCancel`: label for the cancel button
 * @property `onConfirm`: function to be called on confirm
 * @property `onCancel`: function to be called on cancel
 */
const confirmPlugin = {
    install(app: App, globalOptions: Options): void {
        const options = globalOptions || {};

        if (options.labelConfirm === undefined) options.labelConfirm = 'Ok';
        if (options.labelCancel === undefined) options.labelCancel = 'Abbrechen';
        
        createConfirm(app, options);
    }
};

export default confirmPlugin;
