
import { defineComponent, onMounted, ref, watch } from 'vue'
import { Unique } from '@/utils';

export default defineComponent({
   name: 'UiOption',
   props: { 
        label: {
            type: String,
            default: ""
        },
        options: {
            type: Array,
            default: null
        },   
        value: {
           type: Number,
           default: 0
        },
        name: {
            type: String,
            default: 'option'
        }, 
        alignment: {
            type: String,
            default: 'vertical'
        }   
   },
   data() {
        return {
            modelValue: 0,
            cId: Unique.getUnique(),
            icons: {
                check: '<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M16.5 4.40002L6.9 14L3.8 10.9" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>'
            }
        }
   },
   watch: {
        value() {
            this.modelValue = this.value
        },
   },
   mounted() {
        this.modelValue = this.value;
   },
   methods: {
       handleChange(option: any) {
           this.modelValue = option.value
           this.$emit('onChange', this.modelValue)
       }
   }
})
