
import { defineComponent } from 'vue';

export default defineComponent({
    name: "UiAccordeon",
    props: {
        items: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            activeItem: 0
        }
    },
    methods: {
        handleSetActiveItem(index: number) {
            this.activeItem = index
            this.$emit('onChange', index)
        }
    },

})
