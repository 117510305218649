import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ww-searchbar" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIconButton = _resolveComponent("UiIconButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: "svgicon search-icon",
      innerHTML: _ctx.icons.search
    }, null, 8, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
      placeholder: _ctx.$t('placeholder.search')+'...'
    }, null, 8, _hoisted_3), [
      [_vModelText, _ctx.input]
    ]),
    (_ctx.input)
      ? (_openBlock(), _createBlock(_component_UiIconButton, {
          key: 0,
          icon: _ctx.icons.deleteInput,
          hoverTitle: _ctx.$t('button.reset'),
          onOnClick: _ctx.resetSearch
        }, null, 8, ["icon", "hoverTitle", "onOnClick"]))
      : _createCommentVNode("", true)
  ]))
}