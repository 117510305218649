const Converter =  {

    convertSecondsToTime(seconds:number) {
        let hours : any = Math.floor(seconds / 3600);
        let minutes : any = Math.floor((seconds - (hours * 3600)) / 60);
        let timestring = "";
        if (hours < 10) { hours = "0" + hours; }
            timestring += hours + ':';
        if (minutes < 10) { minutes = "0" + minutes; }
            timestring += minutes;
        return timestring + " h";
    },

    convertTimeStampToDate(tstamp:number) {
        const date = new Date(tstamp * 1000);
        let datestring = "";
        if (date.getDay() < 10) { datestring += "0" + date.getDay() + "."; } else { datestring+= date.getDay() + "."}
        if (date.getDate() < 10) { datestring += "0" + date.getDate() + "."; } else { datestring+= date.getDate() + "."}
        if (date.getFullYear() < 10) { datestring += "0" + date.getFullYear(); } else { datestring+= date.getFullYear()}
        return datestring;
    },

    convertTimeStampToTime(tstamp:number){
        const dateFormat = new Date(tstamp * 1000);
        let hours : any = dateFormat.getHours();
        let minutes : any = dateFormat.getMinutes();

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        
        return hours + ":" + minutes;
    },

    convertToDate(datetime:string) {
        if (datetime) {
            if (datetime.split('T')[0]) {
                datetime = datetime.split('T')[0]
            }
            const date = new Date(datetime)
            const m = date.getMonth()+1
            return (date.getDate() < 10 ? '0' : '') + date.getDate() + '.' + (m < 10 ? '0' : '') + m + '.' + date.getFullYear()
        } else {
            return '-'
        }
    },

    /* extractDateOnly(datetime:string) {
        return datetime.split('T')[0]
    } */

};
export default Converter;