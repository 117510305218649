import type { App } from 'vue';
import type { Options } from './confirm.d';
import pluginUtils from '../pluginUtils';

function formatConfirm(
  headline: string,
  message: string,
  options: Options
): HTMLDivElement {
    const confirm = document.createElement('div');

    confirm.className = `ww-confirm-wrapper`;

        // A11y attributes
    confirm.setAttribute('role', 'dialog');
    confirm.setAttribute('aria-live', 'polite');
    confirm.setAttribute('aria-atomic', 'false');

    let html = `<div class="ww-confirm">`;
    if (headline) {
        html += '<header class="confirm__header"><p>' + headline + '</p></header>';
    }
    if (message) {
        html += '<main class="confirm__message"><p>' + message + '</p></main>';
    }
    html += `<footer class="confirm__footer"><button class="confirm__cancel ww-button secondary dense">${options.labelCancel}</button><button class="confirm__confirm ww-button primary raised">${options.labelConfirm}</button></footer></div>`;
    confirm.innerHTML = html;

    if (options.type) confirm.classList.add(`is-${options.type}`);

    /* if (options.dismiss || (options.dismissErrors && options.type == 'error') || options.duration == 0) {
        confirm.innerHTML += '<button class="confirm__close"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_545_1249)"><path d="M2.64282 2.64307L18.2743 18.2746" stroke="#889C9B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.64282 18.2746L18.2743 2.64307" stroke="#889C9B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_545_1249"><rect width="19.0334" height="19.0334" fill="white" transform="translate(0.941895 0.942139)"/></clipPath></defs></svg></button>';
        confirm.classList.add('is-dismiss');
    } */

    confirm.addEventListener('transitionend', () => {
        confirm.classList.remove('has-transition')
        if (!confirm.classList.contains('is-visible')) removeConfirm(confirm.id);
    });

    return confirm;
}

function showConfirm(instance: HTMLDivElement): void {
    instance.classList.add('has-transition');
    setTimeout(() => {
        instance.classList.add('is-visible');
    }, 10);  
}

function hideConfirm(id: string): void {
    const instance = document.getElementById(id);
    if (instance) {
        instance.classList.add('has-transition');
    
        setTimeout(() => {
            instance.classList.remove('is-visible');
        }, 10);
    }
}

function removeConfirm(id: string): void {
  const el = document.getElementById(id);
  if (el) el.remove();    
}

function addConfirmEventListeners(instance: HTMLDivElement, options: Options): void {
    const btnClose = instance.querySelector('.confirm__close');
    if (btnClose) btnClose.addEventListener('click', ev => {
        hideConfirm(instance.id);
        ev.preventDefault();
    });

    const btnConfirm = instance.querySelector('.confirm__confirm');
    if (btnConfirm) btnConfirm.addEventListener('click', ev => {
        if (typeof options?.onConfirm === "function") options.onConfirm();
        hideConfirm(instance.id);
        ev.preventDefault();
    });

    const btnCancel = instance.querySelector('.confirm__cancel');
    if (btnCancel) btnCancel.addEventListener('click', ev => {
        if (typeof options?.onCancel === "function") options.onCancel();
        hideConfirm(instance.id);
        ev.preventDefault();
    });
} 

function confirmPlugin(app: App, options: Options): void {

    const defaultOptions = options;

    function renderConfirm(headline: string, message: string, localOptions: Options): void {
        
        const mergedOptions = {...defaultOptions, ...localOptions};
        const confirm = formatConfirm(headline, message, mergedOptions);

        const uid = pluginUtils.getUniqe();
        confirm.id = `wwConfirm_${uid}`;

        document.body.appendChild(confirm);

        addConfirmEventListeners(confirm, mergedOptions);

        showConfirm(confirm);

    }

    app.config.globalProperties.$confirm = renderConfirm;
    app.provide('$confirm', renderConfirm);
} 

export default confirmPlugin;