import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const LoginRepository = {

    /**
     * getStoragePid
     *     
     * @returns {Object} server response
     */  
    async getStoragePid() {
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_PID)
            console.log('getStoragePid res', res)
            return res;
        } catch(e){
            console.log('getStoragePid error:', e);
            return {}
        }
    },

    /**
     * checkLogin
     * 
     * @returns {Object} server response
     */  
    async checkLogin() {
        const res = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.CHECK_LOGIN).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('checkLogin res', res)
        return res;
    },    

    /**
     * loginUser
     * 
     * @param {Object} loginData
     * 
     * @returns {Object} server response
     */  
    async loginUser(loginData : string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.LOGIN, loginData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('loginResponse', res)
        return res;
    },

    /**
     * logoutUser
     * 
     * 
     * @returns {Object} server response
     */ 
     async logoutUser() {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.LOGOUT,"logintype=logout").catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('check logout Response', res)
        return res;
    },

}

export default LoginRepository;