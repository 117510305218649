import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ww-password-input" }
const _hoisted_2 = ["type", "placeholder"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: _ctx.inputType,
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
      spellcheck: "false",
      autocomplete: "off"
    }, null, 8, _hoisted_2), [
      [_vModelDynamic, _ctx.modelValue]
    ]),
    _createElementVNode("i", {
      class: "svgicon",
      innerHTML: _ctx.icon,
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleToggleInputType && _ctx.handleToggleInputType(...args)))
    }, null, 8, _hoisted_3)
  ]))
}