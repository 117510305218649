import { LoginRepository } from "../repository";
import { DataService } from '../service';

const LoginController = {
    
    async checkLogin() {
        const res : any = await LoginRepository.checkLogin();
        return res
    },

    async login(userData : any){
        userData = DataService.stringify(userData);
        const response : any = await LoginRepository.loginUser(userData)
        if (response.headers) console.log('response.headers', response.headers['set-cookie'])
        return response
    },

    async logout(){
        const response : any = await LoginRepository.logoutUser();
        return response
    },    
    
}

export default LoginController;