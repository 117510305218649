import type { App } from 'vue';
import type { Options } from './notify.d';
import pluginUtils from '../pluginUtils';

const wwNotifyQueue: Array<{id: string, position?: string, instance: HTMLDivElement, positionTop: number}> = [];

function formatNotify(
  text: string,
  options: Options
): HTMLDivElement {
    const notify = document.createElement('div');

    notify.className = `ww-notify is-${options.position}`;

        // A11y attributes
    notify.setAttribute('role', 'status');
    notify.setAttribute('aria-live', 'polite');
    notify.setAttribute('aria-atomic', 'false');

        // If text
    if (text) notify.textContent = text;
    if (options.type) notify.classList.add(`is-${options.type}`);

    if (options.dismiss || (options.dismissErrors && options.type == 'error') || options.duration == 0) {
        notify.innerHTML += '<button class="notify__close"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_545_1249)"><path d="M2.64282 2.64307L18.2743 18.2746" stroke="#889C9B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.64282 18.2746L18.2743 2.64307" stroke="#889C9B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_545_1249"><rect width="19.0334" height="19.0334" fill="white" transform="translate(0.941895 0.942139)"/></clipPath></defs></svg></button>';
        notify.classList.add('is-dismiss');
    }

    notify.addEventListener('transitionend', () => {
        notify.classList.remove('has-transition')
        if (!notify.classList.contains('is-visible')) removeNotify(notify.id);
    });

    return notify;
}

function showNotify(instance: HTMLDivElement): void {
    instance.classList.add('has-transition');
    setTimeout(() => {
        instance.classList.add('is-visible');
    }, 10);  
}

function hideNotify(id: string): void {
    const instance = document.getElementById(id);
    if (instance) {
        instance.classList.add('has-transition');
    
        setTimeout(() => {
            instance.classList.remove('is-visible');
        }, 10);
    }
}

function removeNotify(id: string): void {
  const index = wwNotifyQueue.findIndex(el => el.id == id);
  let instance:Array<{id: string, position?: string, instance: HTMLDivElement, positionTop: number}> = [];

  if (index > -1) instance = wwNotifyQueue.splice(index, 1);
  if (instance.length) {
    updateSamePositionNotifiesPosition(instance[0].position);
  }

  const el = document.getElementById(id);
  if (el) el.remove();    
}

function updateSamePositionNotifiesPosition(position?: string): void {
    if (!position) return;
      //set new positions of existing elements
    const itemsSamePosition = wwNotifyQueue.filter(item => item.position == position );
    if (position == 'bottom' || position == 'top') itemsSamePosition.reverse();
    let pos = 32;
    if (itemsSamePosition.length) {
      itemsSamePosition.forEach(item => {
        item.instance.classList.add('has-transition');
        setNotifyPosition(item.instance, pos, item.position);
        pos += (item.instance.clientHeight + 16);
      });
    }
}

function getNotifyPosition(options: Options): number {
  const itemsSamePosition = wwNotifyQueue.filter(item => item.position == options.position);
  let pos = 32;
  if (itemsSamePosition.length) {
    itemsSamePosition.forEach(item => pos += (item.instance.clientHeight + 16));
  }    
  return pos;
}

function setNotifyPosition(instance: HTMLDivElement, positionTop: number, position?: string): void {
    if (position == "bottom") {
        instance.style.top = 'auto';
        instance.style.bottom = positionTop + 'px';
    } else {
        instance.style.top = positionTop + 'px';
        instance.style.bottom = 'auto';
    }    
}

function notifyPlugin(app: App, options: Options): void {

    const defaultOptions = options;

    function renderNotify(text: string, options: Options): void {

        const localOptions = {...defaultOptions, ...options};
        const notify = formatNotify(text, localOptions);

        const uid = pluginUtils.getUniqe();
        notify.id = `wwNotify_${uid}`;

        const positionTop = localOptions.position == 'bottom' || localOptions.position == 'top' ? 32 : getNotifyPosition(localOptions);

        wwNotifyQueue.push({
            id: notify.id,
            position: localOptions.position,
            instance: notify,
            positionTop: positionTop
        });

        document.body.appendChild(notify);

        if (localOptions.position == 'bottom' || localOptions.position == 'top') {
            updateSamePositionNotifiesPosition(localOptions.position);
        } else {
            setNotifyPosition(notify, positionTop, localOptions.position);
        } 

        showNotify(notify);

        if (localOptions.duration && !localOptions.dismiss && !(localOptions.dismissErrors && localOptions.type == 'error')) {
            setTimeout(() => {
                hideNotify(notify.id);
            }, localOptions.duration);
        } else {
            const btnClose = notify.querySelector('.notify__close');
            if (btnClose) btnClose.addEventListener('click', ev => {
                hideNotify(notify.id);
                ev.preventDefault();
            });
        }
    }

    app.config.globalProperties.$notify = renderNotify;
    app.provide('$notify', renderNotify);
} 

export default notifyPlugin;