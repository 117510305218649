
import { defineComponent } from 'vue';

export default defineComponent({
    name: "UiTabs",
    props: {
        tabs:{
            type: Array,
            default: null
        },
        hideTabsHead:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeTab: 0
        }
    },
    methods: {
        handleSetActiveTab(index: number) {
            this.activeTab = index
            this.$emit('onSetActiveTab', index)
        }
    },

})
